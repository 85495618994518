<template>
  <div>
    <div class="mid_box">
      <img style="width: 15px" src="../../assets/images/Examine_img/back.png" alt @click="toBack" />
      <span style="margin-right: 15px" @click="toBack">返回</span>
      <el-button type="primary" class="edit" @click="toOpen(false)">
        抽取题目
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>
      <!-- <el-button type="primary" class="edit" @click="toOpen(true)"
        >添加题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>-->
      <el-button type="primary" class="edit" @click="dialogVisible1 = true">
        新增提问
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>
      <div class="toSave1" @click="toSave" v-show="!isCheck">
        <img style="width: 15px" src="../../assets/images/Examine_img/sava.png" alt />
        <span>完成检查</span>
      </div>
      <div class="toSave1">
        <el-select v-model="optionChoosevalue" @change="optionChooseValueChange">
          <el-option
            v-for="item in optionChoose"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <el-button style="margin-left: 10px" type="primary" class="edit" @click="getPdf()">打印检查表</el-button>
      <el-button style="margin-left: 10px" type="primary" class="edit" @click="exportExcel2()">导出检查表</el-button>
      <!-- <div class="toSave1" v-if="isCheck" @click="toChange">
        <img style="width: 15px" src="../../assets/images/Examine_img/sava.png" alt="" />
        <span>已检查</span>
      </div>
      <div class="toSave1" v-else @click="toChange">
        <img style="width: 15px" src="../../assets/images/Examine_img/sava.png" alt="" />
        <span>未检查</span>
      </div>-->
      <span style="margin-top: 5px; margin-left: 8px">
        <img src="../../assets/images/Examine_img/del1.png" alt @click="delItem" />
      </span>
    </div>
    <div style="overflow: hidden; overflow-y: auto" :style="{ height: elementHeight + 'px' }">
      <el-table
        v-if="isCheck"
        ref="multipleTable"
        :data="tableData1.slice(start, end)"
        tooltip-effect="dark"
        height="100%"
        style="width: 100%"
        :key="itemKey"
        id="multipleTable"
        highlight-current-row
        @select="selectChange"
        @select-all="selectAll"
        @row-click="handleRowClick"
        @cell-dblclick="handleCell"
        @selection-change="handleSelectionChange"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        border
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="fileName" label="文件名称" width="180"></el-table-column>
        <el-table-column prop="question" label="问题"></el-table-column>
        <el-table-column
          prop="auditResult"
          align="center"
          label="是否符合要求"
          width="350px"
          v-if="!this.isshowAns"
        >
          <template slot-scope="scope">
            <div class="auditResult_box">
              <!-- 旧逻辑 -->
              <!-- <div
                @click="changeResult(scope.row, 1)"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >
                5分(符合)
              </div>
              <div
                @click="changeResult(scope.row, 3)"
                :class="{ auditactive: scope.row.auditResult === 3 }"
              >
                3分(改进)
              </div>
              <div
                @click="changeResult(scope.row, 0)"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >
                1分(不符)
              </div>
              <div
                @click="changeResult(scope.row, 4)"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >
                0分(不适用)
              </div>-->

              <div @click="changeResult(scope.row, 5)" :class="{ auditactive: scope.row.auditResult === 5 }">5分</div>
              <div @click="changeResult(scope.row, 4)" :class="{ auditactive: scope.row.auditResult === 4 }">4分</div>
              <div @click="changeResult(scope.row, 3)" :class="{ auditactive: scope.row.auditResult === 3 }">3分</div>
              <div @click="changeResult(scope.row, 2)" :class="{ auditactive: scope.row.auditResult === 2 }">2分</div>
              <div @click="changeResult(scope.row, 1)" :class="{ auditactive: scope.row.auditResult === 1 }">1分</div>
              <div @click="changeResult(scope.row, 0)" :class="{ auditactive: scope.row.auditResult === 0 && scope.row.isSuitable === 0}">0分</div>
              <div @click="changeResult(scope.row, 1 ,'不适用')" :class="{ auditactive: scope.row.isSuitable === 1 && scope.row.auditResult === 0 }">不适用</div>

              <!-- <div
                @click="changeResult(scope.row, 2)"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >
                纠正
              </div>-->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="answer"
          v-if="this.isshowAns"
          label="查看答案"
          width="150px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="answer" v-else label="查看答案" width="150px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="primary" class="button" plain @click="isChoose(scope.row)">点击查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-else :style="{ height: elementHeight + 'px' }">
        <el-table
          ref="multipleTable"
          :data="tableData2.slice(start, end)"
          tooltip-effect="dark"
          height="100%"
          style="width: 100%"
          :key="itemKey"
          highlight-current-row
          id="multipleTable"
          @select="selectChange"
          @select-all="selectAll"
          @row-click="handleRowClick"
          @cell-dblclick="handleCell"
          @selection-change="handleSelectionChange"
          :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
          border
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column prop="fileName" label="文件名称" width="180"></el-table-column>
          <el-table-column prop="question" label="问题" width="280"></el-table-column>
          <el-table-column prop="auditResult" align="center" label="是否符合要求" width="350px">
            <template slot-scope="scope">
              <div class="auditResult_box">
                <!-- <div
                @click="changeResult(scope.row, 1)"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >
                5分(符合)
              </div>
              <div
                @click="changeResult(scope.row, 3)"
                :class="{ auditactive: scope.row.auditResult === 3 }"
              >
                3分(改进)
              </div>
              <div
                @click="changeResult(scope.row, 0)"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >
                1分(不符)
              </div>
              <div
                @click="changeResult(scope.row, 4)"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >
                0分(不适用)
                </div>-->

                <div @click="changeResult(scope.row, 5)" :class="{ auditactive: scope.row.auditResult === 5}">5分</div>
                <div @click="changeResult(scope.row, 4)" :class="{ auditactive: scope.row.auditResult === 4}">4分</div>
                <div @click="changeResult(scope.row, 3)" :class="{ auditactive: scope.row.auditResult === 3}">3分</div>
                <div @click="changeResult(scope.row, 2)" :class="{ auditactive: scope.row.auditResult === 2}">2分</div>
                <div @click="changeResult(scope.row, 1)" :class="{ auditactive: scope.row.auditResult === 1}">1分</div>
                <div @click="changeResult(scope.row, 0)" :class="{ auditactive: scope.row.auditResult === 0 && scope.row.isSuitable === 0}">0分</div>
                <div @click="changeResult(scope.row, 1 ,'不适用')" :class="{ auditactive: scope.row.isSuitable === 1 && scope.row.auditResult === 0 }">不适用</div>

                <!-- <div
                @click="changeResult(scope.row, 2)"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >
                纠正
                </div>-->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="imagesCount" label="照片列表" align="center" width="190px
        ">
            <template slot-scope="scope">
              <div class="img_box" @click="openImg(scope.row)">
                <img src="../../assets/images/Examine_img/add.png" alt />
                <span v-show="scope.row.imagesCount === 0">请添加图片</span>
                <span
                  v-show="scope.row.imagesCount > 0"
                  style="margin-right: 50%"
                >{{ scope.row.imagesCount }}</span>
                <img src="../../assets/images/Examine_img/picture.png" alt />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="lawAsk" label="审核意见（双击列表）" show-overflow-tooltip>
            <template slot-scope="scope">
              <input
                v-if="
                AreaAudit.id === scope.row.id && columnID === scope.column.id && status != 2
              "
                type="text"
                @focus="compare(scope.row.lawAsk)"
                @blur="Updata(scope.row, scope.row.lawAsk)"
                v-model="scope.row.lawAsk"
              />
              <span v-else>{{ scope.row.lawAsk }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="answer" label="查看答案" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button type="primary" class="button" plain @click="isChoose(scope.row)">点击查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage1"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="isCheck ? tableData1.length : tableData2.length"
    ></el-pagination>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select v-model="company" placeholder="请选择" class="Company_choose">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属区域:</span>
        <el-select v-model="areaNo" placeholder="请选择" class="Company_choose">
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.areaName"
            :value="item.areaNo"
          ></el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目:</span>
          <!-- <span
            @click="chooseCt"
            style="margin: 10px; color: #4274c0; cursor: pointer"
            ><i class="el-icon-plus"></i> 添加范畴</span
          >-->
        </div>
        <div style="display: flex">
          <span
            style="margin: 10px; margin-left: 1%"
          >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <div class="topic">
            <div>涉及方面</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img src="../../assets/images/Examine_img/top.png" @click="isshow = !isshow" alt />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div style="display: flex" v-for="(item, index) in titlelist" :key="index">
              <span
                style="
                  margin: 10px;
                  margin-left: 1%;
                  margin-bottom: 0px;
                  margin-top: 0px;
                "
              >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <div class="topic1">
                <div>{{ item.auditTypeName }}</div>
                <div class="Centernumbet">
                  <span>{{ item.titleCount }}</span>
                </div>
                <div id="number">
                  <img @click="sub(item)" src="../../assets/images/Examine_img/slices/del1.png" alt />
                  <input
                    type="text"
                    style="ime-mode: disabled"
                    @keyup="test(item)"
                    v-model="item.extractCount"
                  />
                  <img
                    @click="plus(item)"
                    src="../../assets/images/Examine_img/slices/plus1.png"
                    alt
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toReAdd">确定抽题</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增提问" :visible.sync="dialogVisible1" width="38%">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin: 20px; width: 60px">问题描述</div>
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="PostData.question"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddASK">确定提问</el-button>
        <el-button @click="dialogVisible1 = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看/添加图片" :visible.sync="dialogVisibleimg" width="40%">
      <div v-show="status !=2">上传图片</div>
      <el-upload
        v-show="status !=2"
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
        :key="uploadKey"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>
        <i class="el-icon-delete-solid" v-show="index === Curindex" @click="delImg(item)"></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div style="font-size: 18px">答案:{{ answer }}</div>
      <div style="font-size: 18px">图片:</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加范畴" :visible.sync="dialogVisibleC" width="30%" :before-close="handleClose">
      <div style="height: 300px; overflow: hidden; overflow-y: auto">
        <el-tree
          :data="Ctdata"
          show-checkbox
          node-key="id"
          ref="tree"
          @check="iScheck"
          highlight-current
          :props="defaultProps"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleC = false">取 消</el-button>
        <el-button type="primary" @click="gettitle">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="审核结果" :visible.sync="dialogVisibleresult" width="35%" v-dialogDrag>
      <el-form label-width="80px" :inline="true">
        <el-form-item label="审核结果:">
          <!-- <span v-if="AuditStatus == 3">3分(改进)</span>
          <span v-if="AuditStatus == 1">5分(符合)</span>
          <span v-if="AuditStatus == 0">1分(不符)</span>
          <span v-if="AuditStatus == 4">0分(不适用)</span>-->
          <span v-if="AuditStatus == 5">5分</span>
          <span v-if="AuditStatus == 4">4分</span>
          <span v-if="AuditStatus == 3">3分</span>
          <span v-if="AuditStatus == 2">2分</span>
          <span v-if="AuditStatus == 1">1分</span>
          <span v-if="AuditStatus == 0">0分</span>
          <span v-if="AuditStatus == '不适用'">不适用</span>
          <!-- <span v-if="AuditStatus == 2">纠正</span> -->
        </el-form-item>
        <!-- <el-form-item>
          <el-radio v-show="AuditStatus == 0" v-model="radio" label="轻微不符合">轻微不符合</el-radio>
          <el-radio v-show="AuditStatus == 0" v-model="radio" label="严重不符合">严重不符合</el-radio>
        </el-form-item>-->
      </el-form>
      <el-form label-width="80px">
        <el-form-item label="审核意见:">
          <el-input v-model="Adoption" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="违反条款:"
          v-show="AuditStatus === 0 || AuditStatus === 3 || AuditStatus === 2"
        >
          <el-input v-model="curRow.ViolatedTerms"></el-input>
        </el-form-item>-->
        <el-form-item label="上传照片:">
          <el-upload
            action=" https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
            :headers="token"
            :key="uploadKey"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已上传图片</div>
          <div class="imgBox">
            <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="srcList"
                @mouseover="overimg(index)"
              ></el-image>
              <i class="el-icon-delete-solid" v-show="index === Curindex" @click="delImg(item)"></i>
            </span>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="toSure">确认</el-button>
      </span>
    </el-dialog>
    <el-table
      :data="isCheck ? tableData1 : tableData2"
      style="width: 100%; position: absolute; left: -100%; z-index: -999"
      id="pdfDom"
    >
      <el-table-column type="index" label="序号" width="55"></el-table-column>
      <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="question" label="问题" width="400"></el-table-column>
      <el-table-column
        prop="auditResult"
        align="center"
        label="是否符合要求"
        width="220px"
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <!-- <div :class="{ auditactive: scope.row.auditResult === 1 }">是</div>
            <div :class="{ auditactive: scope.row.auditResult === 0 }">否</div>
            <div :class="{ auditactive: scope.row.auditResult === 2 }">纠正</div>
            <div :class="{ auditactive: scope.row.auditResult === 3 }">改进</div>
            <div :class="{ auditactive: scope.row.auditResult === 4 }">不适用</div> -->
            <div :class="{ auditactive: scope.row.auditResult === 5}">5分</div>
            <div :class="{ auditactive: scope.row.auditResult === 4}">4分</div>
            <div :class="{ auditactive: scope.row.auditResult === 3}">3分</div>
            <div :class="{ auditactive: scope.row.auditResult === 2}">2分</div>
            <div :class="{ auditactive: scope.row.auditResult === 1}">1分</div>
            <div :class="{ auditactive: scope.row.auditResult === 0 && scope.row.isSuitable === 0}">0分</div>
            <div :class="{ auditactive: scope.row.isSuitable === 1 && scope.row.auditResult === 0 }">不适用</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        v-if="!this.isshowAns"
        align="center"
        width="190px"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span
              v-show="scope.row.imagesCount > 0"
              style="margin-right: 50%"
            >{{ scope.row.imagesCount }}</span>
            <img src="../../assets/images/Examine_img/picture.png" alt />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="answer" label="答案" v-if="this.isshowAns" show-overflow-tooltip></el-table-column>
      <el-table-column prop="answer" label="答案" v-else show-overflow-tooltip></el-table-column>
      <el-table-column prop="lawAsk" label="审核意见（双击列表）" width="300">
        <template slot-scope="scope">
          <span>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { deleteMethod, get, post, put, baseURL } from '../../api/http'
import { excelAutoWidth } from '../../modules/Utils'
import { exportExcel } from '../../modules/ExportExcel'
import { compressImage } from '../../modules/CompressImage'
import imgTypeChange from '../../modules/imgChange'
export default {
  data() {
    return {
      token: {
        Authorization:
          'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      },
      htmlTitle: '现场检查表',
      elementHeight: 0,
      BUrl: baseURL[process.env.NODE_ENV + ''],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleimg: false,
      dialogVisiblebig: false,
      dialogVisibleAsk: false,
      dialogVisibleC: false,
      dialogVisibleresult: false,
      tableData: [],
      tableData1: [],
      tableData2: [],
      Cid: null,
      itemKey: '',
      textarea: '',
      titlelist: '',
      Ctdata: '',
      imgList: [],
      srcList: [],
      isCheck: true,
      value: '',
      isshowAns: false,
      areaNo: this.$route.query.auditAreaNo,
      company: Number(this.$route.query.upCompanyId),
      number: '',
      url: '',
      radio: '',
      imgSrc: '',
      showImg: false,
      options: [],
      optionChoose: [
        { name: '未检查', id: 0 },
        { name: '已检查', id: 1 },
      ],
      optionChoosevalue: 0,
      Curindex: '',
      AreaAudit: {},
      columnID: '',
      option2: '',
      curlawask: '',
      isSave: true,
      isOver: 0,
      nodeKey: [],
      start: 0,
      end: 10,
      excelAutoWidth: excelAutoWidth,
      Adoption: '',
      currentPage1: '',
      curSize: 10,
      answer: '',
      nodeID: [],
      listID: [],
      curRow: '',
      curNum: '',
      CurId: '',
      defaultProps: {
        label: 'auditTypeName',
        children: 'auditTypelist',
      },
      PostData: {
        auditId: this.$route.query.Id,
        questionId: 0,
        question: '',
        answer: '',
        lawAsk: '',
        auditResult: 0,
        imagesCount: 0,
      },
      AuditStatus: 0,
      isAddQuestion: false,
      uploadKey: 1,
      isSuitable: false,
      status:this.$route.query.status,
    }
  },
  mounted() {
    this.CurId = this.$route.query.Id
    this.getTdata()
    this.getCompany()
    this.getElementHeight()
  },
  methods: {
    getCompany() {
      this.options = this.$store.state.companyList
      get('/api/Area/GetList')
        .then((res) => {
          this.option2 = res.data
        })
        // .then(() => {
        //   this.gettitle(this.areaNo)
        // })
    },
    getTdata(isDelete) {
      get(
        '/api/AreaAuditList/GetByAuditId?AuditId=' + this.$route.query.Id
      ).then((res) => {
        this.tableData = res.data
        if(!isDelete){
          if (Number(this.$route.query.extractCount) - res.data.length > 0) {
            this.$message({
              type: 'success',
              message:
                '重复题数：' +
                (Number(this.$route.query.extractCount) - res.data.length),
            })
          }
        }
        this.tableData1 = this.tableData.filter((item) => {
          return item.auditResult === null
        })
        this.tableData2 = this.tableData.filter((item) => {
          return item.auditResult !== null
        })
        if (!isDelete) return
        var length = this.isCheck
          ? this.tableData1.slice(this.start, this.end)
          : this.tableData2.slice(this.start, this.end)
        if (this.currentPage1 != 1 && length == 0) {
          this.handleCurrentChange(this.currentPage1 - 1)
        }
      }).then(() =>{
        if(this.$route.query.status == 2){
          this.optionChoosevalue = 1;
          this.optionChooseValueChange(1);
        }
      })
    },
    toSave() {
      this.isSave = true
      this.tableData.forEach((e) => {
        if (e.auditResult === null) this.isSave = false
      })
      if (this.isSave) {
        var item = JSON.parse(sessionStorage.getItem('item'))
        if (item.status === 2) {
          this.$message({
            type: 'success',
            message: '已经审核完成，请勿重复完成',
          })
          return
        }
        put('/api/AreaAudit', item).then((res) => {
          this.$message({
            type: 'success',
            message: res.message,
          })
          this.isSave = true
          item.status = 2
          this.status = 2;
          this.$router.push({
          query:{...this.$route.query,status:this.status}
        })
          this.isOver = 1
          sessionStorage.setItem('item', item)
        })
      } else {
        this.$message({
          type: 'success',
          message: '未审核完成',
        })
      }
    },
    optionChooseValueChange(e) {
      this.isCheck = e == 0 ? true : false
      this.AreaAudit = {}
      this.getElementHeight()
    },
    toBack() {
      this.$router.push(
        '/Examine_work?isOver=' +
          (this.isOver ? 1 : 0) +
          '&fromQuery=' +
          this.$route.query.queryAuditType
      )
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = ''
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount
    },
    overimg(index) {
      this.Curindex = index
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('multipleTable').offsetTop + 60 + 52)
      })
    },
    openImg(e) {
      this.Cid = e.id
      this.dialogVisibleimg = true
      this.getimg()
    },
    toChange() {
      this.isCheck = !this.isCheck
      this.start = 0
      this.end = 10
      this.currentPage1 = 1
    },
    toOpen(isAddQuestion) {
      this.isAddQuestion = isAddQuestion
      if (this.tableData2.length != 0 && !isAddQuestion) {
        this.$message({
          type: 'warning',
          message: '已有题目审核过，不可再次次抽题',
        })
        return
      }
      // for (let index = 0; index < this.tableData.length; index++) {
      //   if (this.tableData[index].auditResult !== null) {
      //     return
      //   }
      // }
      this.dialogVisible = true
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          console.log(e.id)
          this.$confirm('此操作将永久删除该图片', '是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              deleteMethod('/api/AreaAuditListImage?Id=' + e.id).then(() => {
                this.getimg()
              })
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              })
            })
        }
      })
    },
    iScheck(e, v) {
      this.nodeID = v.checkedNodes
      this.nodeKey = v.checkedKeys
    },
    chooseCt() {
      this.dialogVisibleC = true
      this.nodeID = []
      this.option2.forEach((i) => {
        if (i.areaNo === this.areaNo) {
          get('/api/AreaAudit/GetAuditTypeList?AreaId=' + i.id).then((res) => {
            this.Ctdata = res.data
          })
        }
      })
      this.$refs.tree.setCheckedKeys(this.nodeKey)
    },
    changeResult(row, num ,score) {
      if(this.status == 2){
        this.$message({
          type: 'warning',
          message: '审核完成，无法修改',
        })
        return
      }
      // this.$refs.upload.clearFiles(); //this.$refs.upload.clearFiles();
      if(score){
        this.AuditStatus = score;
        this.isSuitable = num;
      }else{
        this.AuditStatus = num;
        this.isSuitable = 0;
      }
      this.Adoption = row.lawAsk
      if (num >= 0) {
        this.dialogVisibleresult = true
        this.Cid = row.id
        this.curRow = row
        this.curNum = num
        this.getimg()
        return
      }
      row.auditResult = num
      put('/api/AreaAuditList', row).then((res) => {
        if (res.code != '200') {
          this.$message({
            type: 'error',
            message: res.message,
          })
          row.auditResult = null
          return
        }
        this.$message({
          type: 'success',
          message: '保存成功',
        })
        for (var i = 0; i < this.tableData1.length; i++) {
          if (this.tableData1[i].id === row.id) {
            this.tableData1.splice(i, 1)
          }
        }
        for (var k = 0; k < this.tableData2.length; k++) {
          if (this.tableData2[k].id === row.id) {
            return
          }
        }
        this.tableData2.push(row)
        var length = this.tableData1.slice(this.start, this.end)
        if (this.currentPage1 != 1 && length == 0) {
          this.handleCurrentChange(this.currentPage1 - 1)
        }
      })
    },
    toSure() {
      if(this.isSuitable){
        this.curRow.auditResult = 0
      }else{
        this.curRow.auditResult = this.curNum
      }
      this.curRow.isSuitable = this.isSuitable
      this.curRow.lawAsk = this.Adoption
      this.curRow.UnqualityType = this.radio
      // if (this.curRow.auditResult == 1 && this.srcList.length == 0) {
      //   this.$message({
      //     type: 'info',
      //     message: '需要上传图片后再进行确认',
      //   })
      //   return
      // }
      put('/api/AreaAuditList', this.curRow).then((res) => {
        this.$message({
          type: 'success',
          message: res.code == 200 ? '保存成功' : res.message,
        })
        if (res.code == 200) {
          for (var i = 0; i < this.tableData1.length; i++) {
            if (this.tableData1[i].id === this.curRow.id) {
              this.tableData1.splice(i, 1)
            }
          }
          for (var k = 0; k < this.tableData2.length; k++) {
            if (this.tableData2[k].id === this.curRow.id) {
              return
            }
          }
          this.tableData2.push(this.curRow)
          var length = this.tableData1.slice(this.start, this.end)
          if (this.currentPage1 != 1 && length == 0) {
            this.handleCurrentChange(this.currentPage1 - 1)
          }
        } else {
          this.curRow.auditResult = null
        }
      })
      this.uploadKey++
      this.$refs.upload.uploadFiles = []
      this.dialogVisibleresult = false
      this.srcList = []
      this.Adoption = ''
      this.radio = ''
    },
    gettitle() {
      this.nodeID = this.nodeID.reverse()
      for (let index = 0; index < this.nodeID.length; index++) {
        if (this.nodeKey.includes(this.nodeID[index].upId)) {
          this.nodeKey = this.nodeKey.filter((item) => {
            return item !== this.nodeID[index].id
          })
        }
      }
      this.listID = []
      for (let index = 0; index < this.nodeKey.length; index++) {
        var item = {
          AuditTypeId: this.nodeKey[index],
        }
        this.listID.push(item)
      }
      //this.nodeID = this.nodeID.reverse();
      var id
      this.option2.forEach((i) => {
        if (i.areaNo === this.areaNo) {
          id = i.id
        }
      })
      post('/api/AreaAudit/GetTitleList?AreaId=' + id, {
        data: JSON.stringify(this.listID),
      }).then((res) => {
        this.dialogVisibleC = false
        this.titlelist = res.data
        this.nodeID = []
      })
    },
    compare(e) {
      this.curlawask = e
      //console.log(this.curlawask)
    },
    Updata(row, e) {
      this.columnID = ''
      if (this.curlawask === e) return
      put('/api/AreaAuditList', row).then((res) => {
        console.log(res)
        this.$message({
          type: 'success',
          message: res.code == 200 ? '更改成功' : res.message,
        })
        //this.getTdata()
      })
    },
    toReAdd() {
      var id
      var areaAudit = {}
      this.option2.forEach((i) => {
        if (i.areaNo === this.areaNo) {
          id = i.id
          areaAudit = {
            id: 0,
            auditAreaNo: i.areaNo,
            auditAreaName: i.areaName,
            upCompanyId: this.company + '',
            remark: this.remark,
            chargeUserId: '',
            planId: '',
            status: 0,
            saveTime: new Date().toISOString(),
            completeTime: new Date().toISOString(),
            saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
            saveUserNo: JSON.parse(sessionStorage.getItem('userInfo')).userNo,
            saveUserName: JSON.parse(sessionStorage.getItem('userInfo'))
              .userName,
            auditType: this.$route.query.queryAuditType,
          }
        }
      })
      var url = ''
      var url1 = '/api/AreaAudit/InsertExtract' //第一次
      var url2 = '/api/AreaAudit/AgainExtract' //重新
      var url3 = '/api/AreaAudit/InsertAreaAudit' //增量
      var data = {}
      if (this.tableData.length == 0 && this.tableData2.length == 0) {
        url = url1
        data = { data: JSON.stringify(this.titlelist) }
      } else if (this.isAddQuestion) {
        url = url3
        data = {
          jsonData: {
            data: JSON.stringify(this.titlelist),
          },
          areaAudit: areaAudit,
        }
      } else {
        url = url2
        data = { data: JSON.stringify(this.titlelist) }
      }
      post(
        url + '?AuditId=' + this.$route.query.Id + '&AreaId=' + id,
        data
      ).then((res) => {
        this.getTdata()
        this.dialogVisible = false
        this.$message({
          type: 'success',
          message: res.code == 200 ? '更改成功' : res.message,
        })
      })
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleClose() {},
    handlePictureCardPreview() {
      this.dialogVisiblebig = true
    },
    exportExcel2() {
      this.start = 0
      this.isshowAns = true
      this.end = this.tableData.length
      this.tableData1 = this.tableData
      this.tableData2 = this.tableData
      this.$nextTick(() => {
        exportExcel('#pdfDom', '现场检查.xlsx')
        this.start = 0
        this.end = 10
        this.isshowAns = false
        this.getTdata()
      })
    },
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file)
        let newfile = await compressImage(file)
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile)
        }
        this.uploadFileReq(newfile)
      }
    },
    uploadFileReq(file) {
      var formData = new FormData()
      formData.append('files', file)
      post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          if (res.code == 200) {
            var data = {
              AuditListId: this.Cid,
              ImageRemark: '',
              ImageAddress: this.BUrl + '/uploads/' + res.value,
              saveUserNo: JSON.parse(sessionStorage.getItem('userInfo')).userNo,
              saveUserName: JSON.parse(sessionStorage.getItem('userInfo'))
                .userName,
            }
            post('/api/AreaAuditListImage', {
              data: JSON.stringify([data]),
            }).then((res) => {
              if(res.code == 200){
                this.getimg()
              }else{
                this.$message.error(res.message)
              }
            })
          } else {
            this.uploadKey++
            this.$refs.upload.uploadFiles = []
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$refs.upload.uploadFiles = []
          this.uploadKey++
          this.$message.error('上传失败')
        })
    },
    getimg() {
      this.srcList = []
      // this.$refs.upload.uploadFiles = []
      this.uploadKey++
      get('/api/AreaAuditListImage/' + this.Cid).then((res) => {
        this.imgList = res.value
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr)
        })
        this.curRow.imagesCount = this.srcList.length
      })
    },
    toAddASK() {
      this.dialogVisible1 = false
      post('/api/AreaAuditList', this.PostData).then((res) => {
        if(res.code == 200){
          this.$message.success('新增成功');
          this.getTdata()
        }else{
          this.$message.error(res.message);
        }
      })
    },
    toPostImg() {
      this.dialogVisibleimg = false
      this.dialogVisibleAsk = false
      this.getTdata()
      this.$refs.upload.uploadFiles = []
      this.uploadKey++
    },
    handleCell(row, column) {
      this.AreaAudit = row
      this.columnID = column.id
    },
    togetArea(e) {
      get('/api/Area/GetByCompId?CompId=' + e).then((res) => {
        this.option2 = res.data
      })
    },
    delItem() {
      if(this.status == 2){
        this.$message.warning('审核完成，不能删除')
        return
      }
      if (JSON.stringify(this.AreaAudit) == '{}') {
        this.$message({
          type: 'info',
          message: '未选择题目',
        })
        return
      }
      // if (this.AreaAudit.questionId != 0) {
      //   this.$message({
      //     type: 'info',
      //     message: '非自定义题目不可删除',
      //   })
      //   return
      // }
      this.$confirm('此操作将永久删除该题目', '是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteMethod('/api/AreaAuditList?Id=' + this.AreaAudit.id).then(
            (res) => {
              this.getTdata(true)
              this.$message({
                type: 'success',
                message: res.message,
              })
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value
    },

    selectChange(selection, v) {
      this.AreaAudit = v
      console.log(selection, v)
      this.$refs.multipleTable.setCurrentRow(v)
    },
    handleRowClick(row) {
      this.AreaAudit = row
      //this.$refs.multipleTable.toggleRowSelection(row);
      //   this.selectChange(this.handleSelectionList);
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1
      }
    },
    isChoose(e) {
      this.srcList = []
      var newStr = e.answer.replace(/\d+/g, function () {
        console.log(arguments)
        if (arguments[2][arguments[1] + 1] == '分') {
          return ';      ' + arguments[0]
        } else {
          return arguments[0]
        }
      })
      this.answer = newStr
      this.dialogVisibleAsk = true
      if (e.questionImageAddr) {
        var imgdata = e.questionImageAddr.split(',')
        console.log(imgdata)
        imgdata.forEach((e) => {
          this.srcList.push(this.BUrl + '/uploads/' + e)
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
.disFunc {
  pointer-events: none;
}
.disFunc .el-button {
  background-color: #e6e6e6;
  color: silver;
  border: none;
}
/* ::v-deep .el-table__body-wrapper{
  height: calc(100% - 41px) !important;
} */
.imgBox{
  white-space: nowrap;
  overflow-x: auto;
  .ImgDel{
    margin-right: 5px;
  }
}
@import '../../assets/style/Examine_CSS.css';
</style>
